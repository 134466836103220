<style lang="scss" scoped>
.page-stock-distribution {
    .asset-image {
        width: 26px;
        height: 26px;
        vertical-align: middle;
        .error-image {
            font-size: 26px;
            color: #cacaca;
        }
    }
    .pagination-container {
        text-align: right;
        margin-top: 10px;
    }
}
.assets {
    .asset-image {
        width: 26px;
        height: 26px;
        vertical-align: middle;
        .error-image {
            font-size: 26px;
            color: #cacaca;
        }
    }
}
</style>

<template>
    <div class="page-stock-distribution">
        <div>
            <el-table :data="tableData" style="width: 100%" border size="medium">
                <el-table-column label="所属事业部" prop="company.name"></el-table-column>
                <el-table-column label="拥有者类型【名称】" min-width="120">
                    <template slot-scope="scope">
                        {{ tranceOwnerType(scope.row) }}<span class="col_primary" v-if="tranceOwnerName(scope.row)">【{{ tranceOwnerName(scope.row) }}】</span>
                    </template>
                </el-table-column>
                <el-table-column label="库存数量" prop="stock"></el-table-column>
            </el-table>

            <div class="pagination-container" v-if="isPagination">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" 
                    :current-page="listQuery.page" :page-sizes="appPageSizes" :page-size="listQuery.size" 
                    :layout="$root.isPc ? 'total, sizes, prev, pager, next, jumper' : 'pager'" :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import enumConfigs from "@/mixins/enumConfigs"
import * as funInfomation from "@/api/information"
import * as funStock from "@/api/stock"
export default {
    name: "pageTakeStock",
    mixins: [enumConfigs],
    data() {
        return {
            refCascader: "refTakeStockToCascader",
            tableData: [],
            total: null,
            listQuery: {
                companyId: "",
                categoryId: null,
                ownerType: null,
                ownerId: null,
                assetId: "",
                keywords: "",
                page: 1,
                size: window.$common.appPageSize
            },
            isPagination: false,
            defaultProps: {
                value: "id",
                children: "children",
                label: "name",
                expandTrigger: "hover",
                checkStrictly: true,
                emitPath: false
            },
            allCompany: [],
            allWarehouse: [],
            allAssetUser: [],
            allCategorys: []
        }
    },
    mounted() {
        
    },
    methods: {
        async open(params) {
            for (let key in params) {
                this.listQuery[key] = params && params[key] || null
            }
            await this.initData()
        },
        handleSizeChange(val) {
            window.$common.fullLoading()
            this.listQuery.size = val
            this.getDataList()
        },
        handleCurrentChange(val) {
            window.$common.fullLoading()
            this.listQuery.page = val
            this.getDataList()
        },
        async initData() {
            await this.getAllCompany()
            await this.getAllWarehouse()
            await this.getAllAssetUser()
            await this.getAssetTypes()
            this.getDataList()
        },
        getDataList() {
            window.$common.fullLoading()
            funStock.getStockList(this.listQuery).then(response => {
                window.$common.closeFullLoading()
                this.total = response.totalCount
                this.isPagination = response.totalCount > 0 || false
                if (response && response.items && response.items.length > 0) {
                    this.tableData = response.items
                } else {
                    this.tableData = []
                }
            })
        },
        async getAllCompany(){
            await funInfomation.searchCompany().then(res => {
                this.allCompany = res.items
            })
        },
        async getAllWarehouse() {
            await funInfomation.searchWarehouse({ companyId: this.listQuery.companyId }).then(res => {
                this.allWarehouse = res.items
            })
        },
        async getAllAssetUser() {
            await funInfomation.searchAssetUser({ companyId: this.listQuery.companyId }).then(res => {
                this.allAssetUser = res.items
            })
        },
        async getAssetTypes() {
            await funInfomation.getAssetCategory().then(res => {
                window.$common.closeFullLoading()
                this.allCategorys = this.parseCategoryTree(res)
            })
        },
        parseCategoryTree(datas) {
            let trees = []
            for(let i in datas) {
                let temp = datas[i]
                if (datas[i].children && datas[i].children.length > 0) {
                    temp.children = this.parseCategoryTree(datas[i].children)
                } else {
                    delete temp.children
                }
                trees.push(temp)
            }
            return trees
        },
        closeCascader() {
            this.$refs[this.refCascader].dropDownVisible = false
        },
        // 转换类型
        tranceOwnerType(row) {
            let temp = this.arrAssetOwnerType.find(x => x.value === row.ownerType)
            return temp && temp.desc || ""
        },
        // 所属仓库
        tranceOwnerName(row) {
            let temp = {}
            if (row.ownerType === this.valOwnerWarehouse()) {
                temp = this.allWarehouse.find(x => {
                    return row.ownerId === x.id
                })
            } else if (row.ownerType === this.valOwnerAssetUser()) {
                temp = this.allAssetUser.find(x => {
                    return row.ownerId === x.id
                })
            }
            return temp && temp.name || ""
        },
        _search() { 
            this.closeCascader()
            this.listQuery.page = 1
            this.getDataList()
        }
    }
}
</script>