export default {
    data () {
        return {
            AssetStockChageReason: "AssetStockChageReason",
            StockBillStatus: "StockBillStatus",
            AssetOwnerType: "AssetOwnerType"
        }
    },
    computed: {
        arrAssetStockChageReason() {
            // 资产库存变动原因
            return  this.getEnumConfigs(this.AssetStockChageReason).arrs
        },
        arrStockBillStatus() {
            // 库存单状态
            return  this.getEnumConfigs(this.StockBillStatus).arrs
        },
        arrAssetOwnerType() {
            // 资产拥有者类型
            return  this.getEnumConfigs(this.AssetOwnerType).arrs
        },
    },
    methods: {
        // 库存
        valStockInbound() {
            // 入库
            return this.getEnumConfigsItem(this.getEnumConfigs(this.AssetStockChageReason).objs, "Inbound", "value")
        },
        valStockInboundCancel() {
            // 取消入库
            return this.getEnumConfigsItem(this.getEnumConfigs(this.AssetStockChageReason).objs, "InboundCancel", "value")
        },
        valStockOutbound() {
            // 出库
            return this.getEnumConfigsItem(this.getEnumConfigs(this.AssetStockChageReason).objs, "Outbound", "value")
        },
        valStockOutboundCancel() {
            // 撤销出库
            return this.getEnumConfigsItem(this.getEnumConfigs(this.AssetStockChageReason).objs, "OutboundCancel", "value")
        },
        valStockExchangeIn() {
            // 更换入库
            return this.getEnumConfigsItem(this.getEnumConfigs(this.AssetStockChageReason).objs, "ExchangeIn", "value")
        },
        valStockExchangeOut() {
            // 更换出库
            return this.getEnumConfigsItem(this.getEnumConfigs(this.AssetStockChageReason).objs, "ExchangeOut", "value")
        },
        valStockExchangeCancel() {
            // 撤销更换
            return this.getEnumConfigsItem(this.getEnumConfigs(this.AssetStockChageReason).objs, "ExchangeCancel", "value")
        },
        valStockTransferIn() {
            // 调库入库
            return this.getEnumConfigsItem(this.getEnumConfigs(this.AssetStockChageReason).objs, "TransferIn", "value")
        },
        valStockTransferOut() {
            // 调库出库
            return this.getEnumConfigsItem(this.getEnumConfigs(this.AssetStockChageReason).objs, "TransferOut", "value")
        },
        valStockTransferCancel() {
            // 撤销调库
            return this.getEnumConfigsItem(this.getEnumConfigs(this.AssetStockChageReason).objs, "TransferCancel", "value")
        },
        valStockDamage() {
            // 损耗
            return this.getEnumConfigsItem(this.getEnumConfigs(this.AssetStockChageReason).objs, "Damage", "value")
        },
        valStockDamageCancel() {
            // 撤销损耗
            return this.getEnumConfigsItem(this.getEnumConfigs(this.AssetStockChageReason).objs, "DamageCancel", "value")
        },

        // 库存单状态
        valBillSubmited() {
            // 已提交
            return this.getEnumConfigsItem(this.getEnumConfigs(this.StockBillStatus).objs, "Submited", "value")
        },
        valBillCompleted() {
            // 已完成
            return this.getEnumConfigsItem(this.getEnumConfigs(this.StockBillStatus).objs, "Completed", "value")
        },
        valBillPending() {
            // 处理中
            return this.getEnumConfigsItem(this.getEnumConfigs(this.StockBillStatus).objs, "Pending", "value")
        },
        valBillCanceled() {
            // 已撤销
            return this.getEnumConfigsItem(this.getEnumConfigs(this.StockBillStatus).objs, "Canceled", "value")
        },

        // 拥有者类型
        valOwnerWarehouse() {
            // 仓库类型
            return this.getEnumConfigsItem(this.getEnumConfigs(this.AssetOwnerType).objs, "Warehouse", "value")
        },
        valOwnerAssetUser() {
            // 使用者类型
            return this.getEnumConfigsItem(this.getEnumConfigs(this.AssetOwnerType).objs, "AssetUser", "value")
        },
        valOwnerTemporary() {
            // 临时类型
            return this.getEnumConfigsItem(this.getEnumConfigs(this.AssetOwnerType).objs, "Temporary", "value")
        },
        valOwnerDamaged() {
            // 报损类型
            return this.getEnumConfigsItem(this.getEnumConfigs(this.AssetOwnerType).objs, "Damaged", "value")
        }
    }
  }
  
  
  
