import request from '@/utils/request'

// 库存
export function getStockSearch(data) {
    return request({
        url: '/api/app/stock/search',
        method: 'get',
        params: data
    })
}

export function getStockSnSearch(data) {
    return request({
        url: '/api/app/stock/serial-number-search',
        method: 'get',
        params: data
    })
}

export function getStockList(data) {
    return request({
        url: '/api/app/stock',
        method: 'get',
        params: data
    })
}

export function getStockAssets(data) {
    return request({
        url: '/api/app/stock/assets',
        method: 'get',
        params: data
    })
}

export function getStockLogs(data) {
    return request({
        url: '/api/app/stock/logs',
        method: 'get',
        params: data
    })
}

// 入库
export function inStock(data) {
    return request({
        url: '/api/app/stock-inbound',
        method: 'post',
        data
    })
}

export function cancelInStock(data) {
    return request({
        url: '/api/app/stock-inbound/cancel/' + data.billId,
        method: 'post',
        data
    })
}

export function getInStockList(data) {
    return request({
        url: '/api/app/stock-inbound/bills',
        method: 'get',
        params: data
    })
}

// 出库
export function outStock(data) {
    return request({
        url: '/api/app/stock-outbound',
        method: 'post',
        data
    })
}

export function cancelOutStock(data) {
    return request({
        url: '/api/app/stock-outbound/cancel/' + data.billId,
        method: 'post',
        data
    })
}

export function getOutStockList(data) {
    return request({
        url: '/api/app/stock-outbound/bills',
        method: 'get',
        params: data
    })
}

// 更换
export function exchangeStock(data) {
    return request({
        url: '/api/app/stock-exchange',
        method: 'post',
        data
    })
}

export function completeExchangeStock(data) {
    return request({
        url: '/api/app/stock-exchange/complete/' + data.billId,
        method: 'post',
        data
    })
}

export function cancelExchangeStock(data) {
    return request({
        url: '/api/app/stock-exchange/cancel/' + data.billId,
        method: 'post',
        data
    })
}

export function getExchangeStockList(data) {
    return request({
        url: '/api/app/stock-exchange/bills',
        method: 'get',
        params: data
    })
}

// 调库
export function transferStock(data) {
    return request({
        url: '/api/app/stock-transfer',
        method: 'post',
        data
    })
}

export function completeTransferStock(data) {
    return request({
        url: '/api/app/stock-transfer/complete/' + data.billId,
        method: 'post',
        data
    })
}

export function cancelTransferStock(data) {
    return request({
        url: '/api/app/stock-transfer/cancel/' + data.billId,
        method: 'post',
        data
    })
}

export function getTransferStockList(data) {
    return request({
        url: '/api/app/stock-transfer/bills',
        method: 'get',
        params: data
    })
}

// 报废
export function damageStock(data) {
    return request({
        url: '/api/app/stock-damage',
        method: 'post',
        data
    })
}

export function cancelDamageStock(data) {
    return request({
        url: '/api/app/stock-damage/cancel/' + data.billId,
        method: 'post',
        data
    })
}

export function getDamageStockList(data) {
    return request({
        url: '/api/app/stock-damage/bills',
        method: 'get',
        params: data
    })
}

export function getSerialNumberDetail(data) {
    return request({
        url: '/api/app/asset-serial-number/' + data.id,
        method: 'get',
        params: data
    })
}