<style lang="scss" scoped>
.page-take-stock {
    .asset-image {
        width: 26px;
        height: 26px;
        vertical-align: middle;
        .error-image {
            font-size: 26px;
            color: #cacaca;
        }
    }
}
.assets {
    .asset-image {
        width: 26px;
        height: 26px;
        vertical-align: middle;
        .error-image {
            font-size: 26px;
            color: #cacaca;
        }
    }
}
/deep/ .distribution-drawer {
    min-width: 300px;
    padding: 12px 15px 12px;
    .el-drawer__header {
        margin-bottom: 15px;
        padding: 0;
        font-size: 15px;
    }
}
</style>

<template>
    <div class="page-take-stock">
        <div class="toolbars mrgb5">
            <div class="buttons mrgb5">
                <div class="fr">
                    <el-select class="c-el-input mrgr5 mrgb5" placeholder="全部类型" size="medium" filterable clearable
                        v-model="listQuery.ownerType" @change="changeOwner">
                        <el-option v-for="opt in arrAssetOwnerType" :key="opt.value" :label="opt.desc" :value="opt.value"></el-option>
                    </el-select>
                    <el-select class="c-el-input mrgr5 mrgb5" placeholder="全部属事业部" size="medium" filterable clearable
                        v-model="listQuery.companyId" @change="changeCompany">
                        <el-option v-for="ws in allCompany" :key="ws.id" :label="ws.name" :value="ws.id"></el-option>
                    </el-select>
                    <el-select class="c-el-input mrgr5 mrgb5" placeholder="全部仓库" size="medium" filterable clearable
                        v-model="listQuery.ownerId" @change="_search" v-if="listQuery.ownerType === valOwnerWarehouse()">
                        <el-option v-for="ws in allWarehouse" :key="ws.id" :label="ws.name" :value="ws.id"></el-option>
                    </el-select>
                    <el-select class="c-el-input mrgr5 mrgb5" placeholder="全部使用者" size="medium" filterable clearable
                        v-model="listQuery.ownerId" @change="_search" v-if="listQuery.ownerType === valOwnerAssetUser()">
                        <el-option v-for="ws in allAssetUser" :key="ws.id" :label="ws.name" :value="ws.id"></el-option>
                    </el-select>
                    <el-cascader :ref="refCascader" class="mrgr5 mrgb5" popper-class="c-no-cascader-radio" size="medium" v-model="listQuery.categoryId" :options="allCategorys" placeholder="请选择资产类别" 
                        :props="defaultProps" filterable clearable @change="_search()"></el-cascader>
                    <el-input class="c-el-input mrgr5 mrgb5" placeholder="请输入关键字查询" clearable v-model="listQuery.keywords"
                        size="medium"></el-input>
                    <el-button type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
                </div>
                <div class="clearb"></div>
            </div>
        </div>
        <div>
            <el-table :data="tableData" style="width: 100%" border size="medium">
                <el-table-column label="图片【分类-条形码】资产名称" min-width="280">
                        <template slot-scope="scope" v-if="scope.row">
                            <el-image class="asset-image" v-if="scope.row.imageUrl"
                                :src="uploadBaseUrl + scope.row.imageUrl" 
                                :preview-src-list="[uploadBaseUrl + scope.row.imageUrl]">
                                <div slot="error">
                                    <i class="el-icon-picture-outline error-image"></i>
                                </div>
                            </el-image>
                            <span>{{ mxTranceAssetData(scope.row) }}</span>
                            <span :class="mxTranceAssetData(scope.row) ? '' : 'mrgl10'">{{ scope.row.name }}</span>
                        </template>
                    </el-table-column>
                <el-table-column label="资产总量" prop="totalCount">
                    <template slot-scope="scope">
                        <a href="javascript:void(0);" :class="tranceTxtClass(scope.row, '')" @click="queryDist(scope.row, '')">{{ scope.row.totalCount }}</a>
                    </template>
                </el-table-column>
                <el-table-column label="仓库库存" prop="warehouse.stock">
                    <template slot-scope="scope">
                        <a href="javascript:void(0);" :class="tranceTxtClass(scope.row, 'warehouse')" @click="queryDist(scope.row, 'warehouse')">{{ scope.row.warehouse && scope.row.warehouse.stock }}</a>
                    </template>
                </el-table-column>
                <el-table-column label="使用者库存" prop="assetUser.stock" min-width="100">
                    <template slot-scope="scope">
                        <a href="javascript:void(0);" :class="tranceTxtClass(scope.row, 'assetUser')" @click="queryDist(scope.row, 'assetUser')">{{ scope.row.assetUser && scope.row.assetUser.stock }}</a>
                    </template>
                </el-table-column>
                <el-table-column label="临时库存" prop="temporary.stock">
                    <template slot-scope="scope">
                        <a href="javascript:void(0);" :class="tranceTxtClass(scope.row, 'temporary')" @click="queryDist(scope.row, 'temporary')">{{ scope.row.temporary && scope.row.temporary.stock }}</a>
                    </template>
                </el-table-column>
                <el-table-column label="报废库存" prop="damaged.stock">
                    <template slot-scope="scope">
                        <a href="javascript:void(0);" :class="tranceTxtClass(scope.row, 'damaged')" @click="queryDist(scope.row, 'damaged')">{{ scope.row.damaged && scope.row.damaged.stock }}</a>
                    </template>
                </el-table-column>
            </el-table>

            <div class="pagination-container" v-if="isPagination">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" 
                    :current-page="listQuery.page" :page-sizes="appPageSizes" :page-size="listQuery.size" 
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>

        <!--库存分布-->
        <el-drawer :title="drawerTitle"  custom-class="distribution-drawer" :size="$root.isPc ? '60%' : '100%'" :visible.sync="showDrawer" direction="rtl" 
            :append-to-body="true">
            <distribution :ref="refDistribution"></distribution>
        </el-drawer>
    </div>
</template>

<script>
import enumConfigs from "@/mixins/enumConfigs"
import * as funInfomation from "@/api/information"
import * as funStock from "@/api/stock"
import distribution from "./components/distribution"
export default {
    name: "pageTakeStock",
    mixins: [enumConfigs],
    components: { distribution },
    data() {
        return {
            refCascader: "refTakeStockToCascader",
            refDistribution: "refTakeStockToDistribution",
            tableData: [],
            total: null,
            listQuery: {
                ownerType: null,
                ownerId: "",
                companyId: "",
                categoryId: null,
                keywords: "",
                page: 1,
                size: window.$common.appPageSize
            },
            isPagination: false,
            defaultProps: {
                value: "id",
                children: "children",
                label: "name",
                expandTrigger: "hover",
                checkStrictly: true,
                emitPath: false
            },
            allCompany: [],
            allWarehouse: [],
            allAssetUser: [],
            allCategorys: [],
            showDrawer: false,
            drawerTitle: "库存分布"
        }
    },
    mounted() {
        this.initData()
    },
    methods: {
        handleSizeChange(val) {
            window.$common.fullLoading()
            this.listQuery.size = val
            this.getDataList()
        },
        handleCurrentChange(val) {
            window.$common.fullLoading()
            this.listQuery.page = val
            this.getDataList()
        },
        async initData() {
            await this.getAllCompany()
            await this.getAllWarehouse()
            await this.getAllAssetUser()
            await this.getAssetTypes()
            this.getDataList()
        },
        getDataList() {
            window.$common.fullLoading()
            funStock.getStockAssets(this.listQuery).then(response => {
                window.$common.closeFullLoading()
                this.total = response.totalCount
                this.isPagination = response.totalCount > 0 || false
                if (response && response.items && response.items.length > 0) {
                    this.tableData = response.items.map(x => {
                        let stocks = this.tranceStocks(x.stocks)
                        x = { ...x, ...stocks }
                        return x
                    })
                } else {
                    this.tableData = []
                }
            })
        },
        tranceStocks(stocks) {
            let temp = {
                warehouse: { stock: 0 },
                assetUser: { stock: 0 },
                temporary: { stock: 0 },
                damaged: { stock: 0 }
            }
            for (let i in stocks) {
                let item = stocks[i]
                switch(item.ownerType) {
                    case this.valOwnerWarehouse(): temp.warehouse = item; break;
                    case this.valOwnerAssetUser(): temp.assetUser = item; break;
                    case this.valOwnerTemporary(): temp.temporary = item; break;
                    case this.valOwnerDamaged(): temp.damaged = item; break;
                }
            }
            return temp
        },
        queryDist(row, type) {
            if (type) {
                if (row[type].stock <= 0) {
                    return false
                }
            } else {
                if (row.totalCount <= 0) {
                    return false
                }
            }
            
            this.showDrawer = true
            this.drawerTitle = `库存分布：${this.mxTranceAssetData(row)} ${row.name}`
            setTimeout(() => {
                let params = {
                    companyId: this.listQuery.companyId,
                    categoryId: this.listQuery.categoryId,
                    ownerType: type && row[type].ownerType || null,
                    ownerId: this.listQuery.ownerId || "",
                    assetId: row.id,
                }
                this.$refs[this.refDistribution] && this.$refs[this.refDistribution].open(params)
            }, 100)
        },
        tranceTxtClass(row, type) {
            let back = "tdu col_primary"
            if (type) {
                if (row[type].stock <= 0) {
                    back = "col9"
                }
            } else {
                if (row.totalCount <= 0) {
                    back = "col9"
                }
            }
            return back
        },
        async getAllCompany(){
            await funInfomation.searchCompany().then(res => {
                this.allCompany = res.items
            })
        },
        async getAllWarehouse() {
            await funInfomation.searchWarehouse({ companyId: this.listQuery.companyId }).then(res => {
                this.allWarehouse = res.items
            })
        },
        async getAllAssetUser() {
            await funInfomation.searchAssetUser({ companyId: this.listQuery.companyId }).then(res => {
                this.allAssetUser = res.items
            })
        },
        async getAssetTypes() {
            await funInfomation.getAssetCategory().then(res => {
                window.$common.closeFullLoading()
                this.allCategorys = this.parseCategoryTree(res)
            })
        },
        parseCategoryTree(datas) {
            let trees = []
            for(let i in datas) {
                let temp = datas[i]
                if (datas[i].children && datas[i].children.length > 0) {
                    temp.children = this.parseCategoryTree(datas[i].children)
                } else {
                    delete temp.children
                }
                trees.push(temp)
            }
            return trees
        },
        closeCascader() {
            this.$refs[this.refCascader].dropDownVisible = false
        },
        // 类型改变
        changeOwner() {
            this.listQuery.companyId = ""
            this.listQuery.ownerId = ""
            this._search()
        },
        // 事业部改变
        changeCompany() {
            this.listQuery.ownerId = ""
            if (this.listQuery.ownerType === this.valOwnerWarehouse()) {
                this.getAllWarehouse()
            } else if (this.listQuery.ownerType === this.valOwnerAssetUser()) {
                this.getAllAssetUser()
            }
            this._search()
        },
        _search() { 
            this.closeCascader()
            this.listQuery.page = 1
            this.getDataList()
        }
    }
}
</script>